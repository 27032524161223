import { CarbonCalculatorValues } from './types'

const heightTree = (
  age: number,
  calibrationHeight: number,
  calibrationAge: number
) => {
  const b = 0.00644
  const c = 1.0343
  const a = (calibrationHeight - 0.3) / (1 - Math.exp(-b * calibrationAge)) ** c
  return 0.3 + a * (1 - Math.exp(-b * age)) ** c
}

const heightShrub = (
  age: number,
  calibrationHeight: number,
  calibrationAge: number
) => {
  const b = 0.0285
  const c = 0.7491
  const a = (calibrationHeight - 0.3) / (1 - Math.exp(-b * calibrationAge)) ** c
  return 0.3 + a * (1 - Math.exp(-b * age)) ** c
}

const dbhTree = (
  age: number,
  calibrationDbh: number,
  calibrationAge: number
) => {
  const b = 0.00797
  const c = 0.8525
  const d = 4
  const a =
    calibrationDbh / (1 - Math.exp(-b * Math.max(0, calibrationAge - d))) ** c
  return a * (1 - Math.exp(-b * Math.max(0, age - d))) ** c
}

function rcdShrub(age: number, calibrationRcd: number, calibrationAge: number) {
  const b = 0.00694
  const c = 0.7877
  const d = 0
  const a =
    calibrationRcd / (1 - Math.exp(-b * Math.max(0, calibrationAge - d))) ** c
  return a * (1 - Math.exp(-b * Math.max(0, age - d))) ** c
}

const stocking = (
  age: number,
  mortality: number,
  calibrationStems: number,
  calibrationAgeSurvival: number
) => {
  return (
    calibrationStems * (1 - mortality / 100) ** (age - calibrationAgeSurvival)
  )
}

const treeCarbon = (dbh: number, height: number, density: number) => {
  const stemVol = 0.0000483 * (dbh * dbh * height) ** 0.978
  const stemCarbon = (stemVol * density) / 2
  const branchCarbon = 0.0175 * dbh ** 2.2
  const folCarbon = 0.0171 * dbh ** 1.75
  const agCarbon = stemCarbon + branchCarbon + folCarbon
  const bgCarbon = 0.2 * agCarbon
  return agCarbon + bgCarbon
}

const shrubCarbon = (rcd: number, height: number) => {
  const agCarbon = 0.0155 * (rcd * rcd * height) ** 0.976
  const bgCarbon = 0.2 * agCarbon
  return agCarbon + bgCarbon
}

const carbonTrees = ({
  age,
  stems,
  treesPercentage,
  calibrationAgeInput,
  treesSurvival,
  treesHeight,
  treesDbh
}: {
  age: number
  stems: number
  treesPercentage: number
  calibrationAgeInput?: number
  treesSurvival?: number
  treesHeight?: number
  treesDbh?: number
}) => {
  const mortality = 0.3
  const density = 402.5
  const planting = treesPercentage
  const calibrationAge = calibrationAgeInput

  let calibrationAgeSurvival = calibrationAge
  let calibrationSurvival = treesSurvival
  if (!calibrationSurvival) {
    calibrationAgeSurvival = 2
    calibrationSurvival = 80
  }

  let calibrationAgeHeight = calibrationAge
  let calibrationHeight = treesHeight
  if (!calibrationHeight) {
    calibrationAgeHeight = 40
    calibrationHeight = 13.43
  }

  let calibrationAgeDbh = calibrationAge
  let calibrationDbh = treesDbh
  if (!calibrationDbh) {
    calibrationAgeDbh = 40
    calibrationDbh = 24.27
  }

  const calibrationStems =
    ((calibrationSurvival / 100) * stems * planting) / 100

  const height = heightTree(age, calibrationHeight, calibrationAgeHeight!)
  const dbh = dbhTree(age, calibrationDbh, calibrationAgeDbh!)

  const stockingStems = stocking(
    age,
    mortality,
    calibrationStems,
    calibrationAgeSurvival!
  )

  return (
    ((2 * 16 + 12) / 12 / 1000) *
    stockingStems *
    treeCarbon(dbh, height, density)
  )
}

const carbonShrubs = ({
  age,
  stems,
  shrubsPercent,
  calibrationAgeInput,
  shrubsSurvival,
  shrubsHeight,
  shrubsRcd
}: {
  age: number
  stems: number
  shrubsPercent: number
  calibrationAgeInput?: number
  shrubsSurvival?: number
  shrubsHeight?: number
  shrubsRcd?: number
}) => {
  const mortality = 3
  const planting = shrubsPercent

  const calibrationAge = calibrationAgeInput
  let calibrationAgeSurvival = calibrationAge
  let calibrationSurvival = shrubsSurvival

  if (!calibrationSurvival) {
    calibrationAgeSurvival = 2
    calibrationSurvival = 80
  }

  let calibrationAgeHeight = calibrationAge
  let calibrationHeight = shrubsHeight
  if (!calibrationHeight) {
    calibrationAgeHeight = 40
    calibrationHeight = 9.51
  }

  let calibrationAgeRcd = calibrationAge
  let calibrationRcd = shrubsRcd
  if (!calibrationRcd) {
    calibrationAgeRcd = 40
    calibrationRcd = 32.75
  }

  const calibrationStems =
    ((calibrationSurvival / 100) * stems * planting) / 100
  const height = heightShrub(age, calibrationHeight, calibrationAgeHeight!)
  const rcd = rcdShrub(age, calibrationRcd, calibrationAgeRcd!)
  const stockingStems = stocking(
    age,
    mortality,
    calibrationStems,
    calibrationAgeSurvival!
  )
  return ((2 * 16 + 12) / 12 / 1000) * stockingStems * shrubCarbon(rcd, height)
}

export const calculateGraphData = (
  values: CarbonCalculatorValues,
  stems?: number
) => {
  const {
    stems: stemsFromValues,
    trees,
    shrubs,
    standTreeAge,
    standTreeSurvival,
    standTreeHeight,
    standTreeDBH,
    standShrubSurvival,
    standShrubHeight,
    standShrubRCD
  } = values

  let data = Array(17)
  data = [['Age', 'Shrubs', 'Trees']]

  const stemsForCalculation = stemsFromValues || stems || 1000

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 16; i++) {
    data[i] = new Array(3)
    data[i][0] = i * 5
    data[i][1] = carbonShrubs({
      age: i * 5,
      stems: stemsForCalculation,
      shrubsPercent: shrubs!,
      calibrationAgeInput: standTreeAge,
      shrubsSurvival: standShrubSurvival,
      shrubsHeight: standShrubHeight,
      shrubsRcd: standShrubRCD
    })

    data[i][2] = carbonTrees({
      age: i * 5,
      stems: stemsForCalculation,
      treesPercentage: trees!,
      calibrationAgeInput: standTreeAge,
      treesSurvival: standTreeSurvival,
      treesHeight: standTreeHeight,
      treesDbh: standTreeDBH
    })
  }

  return data
}

export type CarbonCalculateResults = { co2Total: number; stems: number }

export const calculateCarbon = (
  values: CarbonCalculatorValues
): CarbonCalculateResults => {
  const {
    emissions,
    stems,
    trees,
    shrubs,
    age,
    standTreeAge,
    standTreeSurvival,
    standTreeHeight,
    standTreeDBH,
    standShrubSurvival,
    standShrubHeight,
    standShrubRCD
  } = values

  const co2Total =
    carbonTrees({
      age,
      stems: stems || 1000,
      treesPercentage: trees!,
      calibrationAgeInput: standTreeAge,
      treesSurvival: standTreeSurvival,
      treesHeight: standTreeHeight,
      treesDbh: standTreeDBH
    }) +
    carbonShrubs({
      age,
      stems: stems || 1000,
      shrubsPercent: shrubs!,
      calibrationAgeInput: standTreeAge,
      shrubsSurvival: standShrubSurvival,
      shrubsHeight: standShrubHeight,
      shrubsRcd: standShrubRCD
    })

  const seqCarbon = emissions || co2Total
  const stemsToFund = (seqCarbon / co2Total) * 1000

  return { co2Total, stems: stemsToFund }
}
