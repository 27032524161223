import { IconProps } from './types'

export const SupportedProjects = ({ className }: IconProps) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clip-path='url(#clip0_878_57256)'>
        <path
          d='M10.7235 28.6826C11.4169 28.6331 16.9146 28.7598 17.7565 28.6826C18.3996 28.6237 20.0348 28.435 21.1245 28.3359C21.8179 28.2864 23.6207 28.1576 25.6811 27.0482C28.2565 25.6614 30.0396 24.2251 31.3768 23.1355C32.7141 22.0458 32.9122 21.501 32.9617 21.1543C33.0113 20.8076 32.615 20.2628 31.9712 20.2628C31.3273 20.2628 30.6834 20.6095 30.4358 20.7581C30.2377 20.877 28.8674 21.7652 28.207 22.1944C27.6787 22.5906 26.3447 23.5416 25.2353 24.1756C24.1259 24.8095 22.5278 24.8689 21.8674 24.8194C21.0419 24.8194 19.0839 24.8095 17.8556 24.7699C16.3202 24.7204 15.7259 23.7298 15.9735 22.7888C16.1716 22.0359 17.1622 21.6001 18.2023 21.6001C19.523 21.5671 21.7683 21.6991 22.858 21.0553C23.5346 20.6554 23.1056 20.3619 22.858 20.2133C22.5926 20.0541 21.6363 19.8996 21.174 19.8666C20.7447 19.8501 19.3315 19.827 17.1127 19.8666C14.3391 19.9161 13.0513 20.1142 11.7141 20.3619C10.3768 20.6095 9.08908 21.7487 8.2471 22.4916C7.57352 23.0859 6.90984 23.2015 6.6622 23.185C6.34852 23.185 5.56267 23.2147 4.92871 23.3336C4.29474 23.4525 4.10323 23.7793 4.08672 23.9279C4.02069 24.2746 3.88861 25.2751 3.88861 26.5034C3.88861 28.0388 4.13625 28.2864 4.58201 28.5836C4.6563 28.6331 7.28625 28.7223 8.5938 28.6826'
          stroke='currentColor'
          strokeWidth='1.3'
          strokeLinecap='round'
        />
        <path
          d='M17.249 14.2153L16.7497 11.9843L16.0006 14.1443C15.8321 14.6299 15.7758 15.0647 15.7856 15.4081C15.7904 15.5776 15.8118 15.7399 15.8528 15.8821C15.8731 15.9522 15.9029 16.0344 15.9487 16.1152C15.9856 16.1805 16.0762 16.3236 16.2526 16.4165L16.3947 16.4913H16.5553C16.8447 16.4913 17.0131 16.3098 17.0462 16.2739C17.1032 16.212 17.1381 16.1512 17.1556 16.1184C17.1936 16.0475 17.2194 15.975 17.2369 15.9183C17.2741 15.7979 17.304 15.6497 17.3229 15.4856C17.3609 15.1546 17.3597 14.7099 17.249 14.2153ZM16.9256 10.4848L16.6474 11.6716L17.7879 11.2413C20.9486 10.0486 22.4377 7.71266 22.799 7.14583C22.8219 7.10988 22.8403 7.08105 22.8543 7.06008C22.9693 6.88754 23.0411 6.65258 23.0838 6.4593C23.1314 6.244 23.1635 5.98512 23.1556 5.7215C23.1478 5.46479 23.1008 5.15512 22.9498 4.87435C22.787 4.57173 22.5068 4.31503 22.0975 4.2246C21.5736 4.10883 21.1179 4.14486 20.6484 4.35575C20.2275 4.54477 19.814 4.86908 19.351 5.24525C18.6391 5.82367 18.1128 6.87284 17.7391 7.82594C17.3524 8.81247 17.0779 9.8349 16.9256 10.4848ZM11.1751 5.54854L11.1579 5.55888L11.1413 5.57027C10.9025 5.73444 10.6026 6.03249 10.4111 6.43859C10.2103 6.86452 10.1279 7.41733 10.3742 8.01553C10.8412 9.1497 12.271 11.2865 15.0613 12.1301L15.8994 12.3834V11.5079C15.8994 10.8395 15.7768 9.77816 15.4147 8.69438C15.0572 7.62456 14.4441 6.46749 13.417 5.6709C13.0248 5.30969 12.1532 4.96165 11.1751 5.54854Z'
          stroke='currentColor'
          strokeWidth='1.3'
        />
        <path
          d='M28.4331 14.16C28.2286 14.0674 27.9841 13.9547 27.7219 13.8313C28.0745 13.977 28.3343 14.0912 28.4375 14.1485C28.4498 14.1553 28.4611 14.1618 28.4714 14.168C28.4575 14.1654 28.4446 14.1627 28.4331 14.16ZM28.638 14.1755C28.638 14.1756 28.6375 14.1757 28.6364 14.1759L28.638 14.1755Z'
          stroke='currentColor'
          strokeWidth='1.42469'
        />
        <path
          d='M27.7229 10.4C27.5849 10.3924 27.4506 10.3842 27.3219 10.3753C27.6534 10.3601 28.0006 10.3455 28.3347 10.3333C29.1565 10.3034 29.8269 10.2919 30.0381 10.3116C30.1187 10.3191 30.1707 10.3299 30.202 10.3387C30.2069 10.3601 30.2116 10.3875 30.2159 10.4226C30.1942 10.4316 30.1693 10.4405 30.1415 10.4488C30.1034 10.4602 30.0674 10.4682 30.038 10.4733C29.4334 10.4667 28.5306 10.4447 27.7229 10.4Z'
          stroke='currentColor'
          strokeWidth='1.42469'
        />
        <path
          d='M28.8826 5.9195C28.6518 6.08555 28.3614 6.29099 28.0499 6.50623C28.4432 6.1995 28.7461 5.97323 28.8676 5.90011C28.8909 5.88611 28.9112 5.87478 28.9287 5.86562C28.9125 5.88635 28.8966 5.90464 28.8826 5.9195ZM29.001 5.74537C29.001 5.74537 29.0009 5.74588 29.0006 5.74692L29.001 5.74537Z'
          stroke='currentColor'
          strokeWidth='1.42469'
        />
      </g>
    </svg>
  )
}
