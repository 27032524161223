import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter
} from 'react-icons/fa'

import { InternalLink, ExternalLink } from 'src/components/atoms/Link'
import { Heading3 } from 'src/components/atoms/Typography'

import {
  aboutUsPath,
  blogPath,
  businessTakeActionPath,
  carbonCalculatorPath,
  contactPath,
  donatePath,
  giftingPath,
  howItWorksPath,
  individualsFamiliesTakeActionPath,
  landscapeScalePath, // landscapeScalePath,
  leaderboardsPath,
  listPlantingProjectsPath,
  listRegistriesPath,
  nativeTreesPath,
  plantersTakeActionPath,
  plantingEventsPath,
  privacyPolicyPath,
  resourcesPath,
  signInPath,
  signUpPath,
  termsPath
} from 'src/config/paths'
import { env } from 'src/env/client.mjs'

import { LinkGroup } from './types'

const helpCentrePath = env.NEXT_PUBLIC_HELPCENTRE_URL

const footerLinks: LinkGroup[] = [
  {
    header: 'Take action',
    links: [
      {
        label: 'Individuals and families',
        path: individualsFamiliesTakeActionPath
      },
      { label: 'Businesses', path: businessTakeActionPath },
      {
        label: 'Landscape scale restoration',
        path: landscapeScalePath
      },
      { label: 'Planters', path: plantersTakeActionPath },
      { label: 'Gifting', path: giftingPath },
      { label: 'Registries', path: listRegistriesPath },
      { label: 'Donate', path: donatePath() }
    ]
  },
  {
    header: 'Explore',
    links: [
      { label: 'Planting projects', path: listPlantingProjectsPath() },
      { label: 'News and stories', path: blogPath() },
      { label: 'Leaderboards', path: leaderboardsPath() },
      { label: 'Events', path: plantingEventsPath },
      { label: 'Resources', path: resourcesPath },
      { label: 'CO2 calculator', path: carbonCalculatorPath },
      { label: 'Meet the trees', path: nativeTreesPath }
    ]
  },
  {
    header: 'About',
    links: [
      { label: 'How it works', path: howItWorksPath },
      { label: 'About us', path: aboutUsPath },
      { label: 'Terms of use', path: termsPath },
      { label: 'Privacy policy', path: privacyPolicyPath }
    ]
  },
  {
    header: 'Get started',
    links: [
      { label: 'Login', path: signInPath() },
      { label: 'Sign up', path: signUpPath() },
      { label: 'Help centre', path: helpCentrePath, isExternal: true },
      { label: 'Contact', path: contactPath }
    ]
  }
]

export const socialLinks = [
  {
    IconComponent: FaFacebookF,
    href: 'https://www.facebook.com/TreesThatCount',
    label: 'Facebook'
  },
  {
    IconComponent: FaInstagram,
    href: 'https://www.instagram.com/treesthatcount',
    label: 'Instagram'
  },
  {
    IconComponent: FaLinkedin,
    href: 'https://www.linkedin.com/company/treesthatcount',
    label: 'LinkedIn'
  },
  {
    IconComponent: FaTwitter,
    href: 'https://twitter.com/TreesThatCount',
    label: 'Twitter'
  },
  {
    IconComponent: FaTiktok,
    href: 'https://www.tiktok.com/@treesthatcount',
    label: 'TikTok'
  }
]

export const Footer = () => {
  const getLinkId = (groupHeader: string, linkLabel: string) => {
    return `link-${groupHeader}-${linkLabel}`
      .replace(/[^a-z0-9]/gi, '-')
      .toLocaleLowerCase()
  }

  return (
    <div className='w-full bg-davyGrey'>
      <div className='container m-auto mb-0 py-10 md:py-14 font-medium z-[1] text-white'>
        <div className='flex flex-col gap-4 md:flex-row justify-between md:space-x-4 px-4'>
          {footerLinks.map(linkGroup => (
            <div key={linkGroup.header}>
              <Heading3>{linkGroup.header}</Heading3>
              <ul>
                {linkGroup.links.map(link => {
                  return (
                    <li
                      key={link.label}
                      id={getLinkId(linkGroup.header, link.label)}
                      className='my-2'
                    >
                      {link.isExternal ? (
                        <ExternalLink
                          className='!text-white hover:underline'
                          href={link.path}
                        >
                          {link.label}
                        </ExternalLink>
                      ) : (
                        <InternalLink
                          className='!text-white hover:underline'
                          href={link.path}
                        >
                          {link.label}
                        </InternalLink>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>
        <div className='flex space-x-4 justify-center mt-16'>
          {socialLinks.map(socialLink => {
            return (
              <ExternalLink
                key={socialLink.href}
                href={socialLink.href}
                aria-label={socialLink.label}
              >
                <socialLink.IconComponent className='text-white' size='24' />
              </ExternalLink>
            )
          })}
        </div>
        <div className='text-center my-4'>
          <span className='mx-4'>
            &copy; {new Date().getFullYear()} Trees That Count, New Zealand
          </span>
        </div>
      </div>
    </div>
  )
}
