import { PortableTextMarkComponent } from '@portabletext/react'

import { InternalLink } from 'src/components/atoms/Link'

import { viewBlogPostPath } from 'src/config/paths'

interface InternalRefType {
  _type: 'post'
  slug: { _type: 'slug'; current: string }
}

interface InternalLinkType {
  _type: 'internalLink'
  path: string
}

export const getInternalRefPath = (
  internalRef: InternalRefType | InternalLinkType
) => {
  switch (internalRef._type) {
    case 'post':
      return viewBlogPostPath({
        routeParams: { slug: internalRef.slug.current }
      })
    case 'internalLink':
      return internalRef.path
    default:
      return ''
  }
}

interface LinkType {
  _type: 'link'
  link: InternalRefType | InternalLinkType
}

export const InternalRefBlock: PortableTextMarkComponent<LinkType> = ({
  children,
  value
}) => {
  return (
    <InternalLink
      color='primary'
      href={value ? getInternalRefPath(value.link) : ''}
    >
      {children}
    </InternalLink>
  )
}
