import { RadioGroupProps, RadioProps } from '../RadioGroup'

export const TreeQuantityRadio = ({
  checked,
  id,
  label,
  onBlur,
  onChange,
  value
}: RadioProps) => {
  const amount = (label as string).split(' ')[0]
  const splitLabel = (label as string).split(' ')[1]
  const isOtherAmount = value === 'other'

  const buildLabelClasses = () => {
    let classes =
      'h-20 p-4 text-mostlyGreen hover:bg-olivineDark hover:text-white rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out'

    if (isOtherAmount) {
      classes += ' block basis-1/3 grow-1 max-w-[13.5rem]'
    } else {
      classes += ' flex flex-col basis-[calc((100%_-_30px)/4)] grow-0'
    }

    if (checked) {
      classes += ' bg-mostlyGreen text-white bg-aquaSpring'
    } else {
      classes += ' bg-aquaSpring border-aquaSpring'
    }

    return classes
  }

  return (
    <label className={buildLabelClasses()}>
      <input
        className='sr-only'
        name={id}
        onBlur={onBlur}
        onChange={onChange}
        type='radio'
        value={value}
        checked={checked}
      />
      <span className='block text-center select-none text-xl leading-7 font-bold'>
        {amount}
      </span>
      <span className='block text-center select-none text-lg leading-5'>
        {splitLabel}
      </span>
    </label>
  )
}

export const TreeQuantityRadioGroup = ({ children, id }: RadioGroupProps) => {
  return (
    <div
      className='flex flex-wrap gap-1 md:gap-[10px]'
      role='radiogroup'
      aria-labelledby={id}
    >
      {children}
    </div>
  )
}
