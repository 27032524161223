import { PortableTextMarkComponent } from '@portabletext/react'

import { InternalLink } from 'src/components/atoms/Link'

interface LinkType {
  _type: 'link'
  href: string
}

export const Link: PortableTextMarkComponent<LinkType> = ({
  children,
  value
}) => {
  return (
    <InternalLink color='primary' href={value?.href || ''}>
      {children}
    </InternalLink>
  )
}
