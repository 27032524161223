import { useMemo } from 'react'

import {
  StandaloneCheckbox,
  CheckboxProps
} from 'src/components/atoms/StandaloneCheckbox'

interface LabelledCheckboxProps extends CheckboxProps {
  checked: boolean
  id: string
  label?: string | JSX.Element
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  className?: string
}

export const LabelledCheckbox = ({
  checked,
  id,
  label,
  onBlur,
  onChange,
  value,
  disabled,
  className = '',
  ...rest
}: LabelledCheckboxProps) => {
  const labelClass = useMemo(() => {
    if (disabled) return 'text-anotherGrey1 cursor-default'
    return 'text-ivoryBlack cursor-pointer'
  }, [disabled])

  return (
    <label
      className={`inline-flex items-center ml-[-11px] mr-4 ${labelClass} ${className}`}
    >
      <StandaloneCheckbox
        id={id}
        checked={checked}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled}
        {...rest}
      />
      <span
        className={`transition-colors duration-200 ease-in-out ${labelClass}`}
      >
        {label}
      </span>
    </label>
  )
}
