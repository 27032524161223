import { PortableTextTypeComponent } from '@portabletext/react'
import { useNextSanityImage } from 'next-sanity-image'

import { Image as ImageComponent } from 'src/components/atoms/Image'
import { ParagraphSmaller } from 'src/components/atoms/Typography'

import {
  SanityImageAsset,
  SanityReference
} from 'src/config/generated/sanitySchema'
import { sanityClient } from 'src/utils/sanity'

import { BodyElement, ElementOf } from '../types'

export const Image: PortableTextTypeComponent<BodyElement> = ({ value }) => {
  const { asset, alt, label } = value as ElementOf<'image'>

  const imageProps = useNextSanityImage(
    sanityClient,
    asset as SanityReference<SanityImageAsset>
  )

  return (
    <div className='text-center bg-linen'>
      <ImageComponent {...imageProps} alt={alt as string} />
      {label && (
        <ParagraphSmaller className='font-medium !pb-[10px] pt-[4px] leading-none'>
          {label}
        </ParagraphSmaller>
      )}
    </div>
  )
}
