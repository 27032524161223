const Slider = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  return (
    <div className='w-full'>
      <input {...props} className={`slider ${className}`} type='range' />
    </div>
  )
}

export default Slider
