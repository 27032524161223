import { IconProps } from './types'

export const DefaultAvatar = ({ className }: IconProps) => {
  return (
    <svg
      width='70'
      height='70'
      viewBox='0 0 70 70'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.2191 26.4731C26.7116 26.4731 26.1857 26.7531 25.8795 27.1862C25.5837 27.6054 25.5164 28.1068 25.6905 28.5985C27.3416 33.271 30.6062 35.9503 34.6487 35.9503C34.9865 35.9503 35.3312 35.9319 35.6839 35.8942C39.8287 35.4392 43.4425 31.1001 43.5755 26.4162C43.5895 25.914 43.3874 25.5972 43.2159 25.4205C42.9814 25.1807 42.6611 25.0469 42.3146 25.0469C41.7039 25.0469 41.1806 25.4608 41.0117 26.0741C40.2767 28.7578 39.008 31.9585 35.6865 32.8921C35.256 33.0137 34.8255 33.075 34.4081 33.075C31.4917 33.075 29.404 30.1157 28.4222 27.3464C28.2271 26.8004 27.7765 26.4731 27.2191 26.4731Z'
        fill='currentColor'
      />
      <path
        d='M31.8582 19.5197C31.8582 19.0962 31.7191 18.7015 31.4654 18.4093C31.2606 18.1722 30.8844 17.8887 30.2579 17.8887C29.1702 17.8887 28.584 18.7908 28.584 19.6395C28.584 20.063 28.7231 20.4568 28.976 20.7499C29.1807 20.987 29.557 21.2705 30.1835 21.2705C31.2711 21.2714 31.8582 20.3684 31.8582 19.5197Z'
        fill='currentColor'
      />
      <path
        d='M37.2158 20.8281C38.3034 20.8281 38.8906 19.9251 38.8906 19.0763C38.8906 18.6537 38.7514 18.2591 38.4986 17.9659C38.2938 17.7288 37.9176 17.4453 37.2911 17.4453C36.2034 17.4453 35.6172 18.3483 35.6172 19.1971C35.6172 19.6197 35.7563 20.0143 36.0092 20.3074C36.2139 20.5446 36.5893 20.8281 37.2158 20.8281Z'
        fill='currentColor'
      />
      <path
        d='M58.7541 44.5703C55.8833 40.6232 51.041 38.9808 46.3563 38.2266C51.6185 32.6721 54.2094 24.1811 50.1074 16.8451C47.1263 11.5163 41.479 8.45996 34.6146 8.45996C31.7586 8.45996 28.8878 9.01821 26.3109 10.0743C25.5733 10.3771 25.4088 10.9817 25.5006 11.4472C25.6555 12.2321 26.5165 12.7676 27.3206 12.5235C29.6543 11.8243 31.9511 11.4691 34.1474 11.4691C39.1804 11.4691 43.4863 13.3652 46.5986 16.9536C48.6881 19.3598 49.6261 22.1791 49.3881 25.3308C48.983 30.7077 45.294 35.6418 41.9769 38.4672C39.701 40.4045 36.9168 41.4291 33.9243 41.4291C30.293 41.4291 26.6171 39.8961 23.8416 37.223C18.0701 31.6702 19.3328 23.167 23.1434 17.2021C23.3613 16.8626 23.3884 16.4907 23.2195 16.1801C23.027 15.8283 22.6018 15.6008 22.1354 15.6008C21.7014 15.6008 21.3216 15.7942 21.0941 16.1311C15.5186 24.3622 15.9596 33.681 22.2185 39.8716C22.3165 39.9687 22.4233 40.0518 22.5239 40.1455C18.4928 40.4858 14.7626 41.927 11.799 45.1697C8.19225 49.116 7.3575 55.3075 7.8125 60.4236C7.94463 61.9058 10.6606 61.919 10.5653 60.4236C10.2923 56.1291 10.6659 51.8171 12.8508 48.0161C15.4364 43.5186 20.2244 42.1711 25.0343 42.0556C27.4186 43.5098 30.16 44.3026 33.0318 44.3026C36.4364 44.3026 39.9329 43.201 42.8755 41.1998C43.6464 40.6757 44.3866 40.0903 45.091 39.4577C49.739 40.4482 54.0703 42.3032 56.9376 46.3168C59.6405 50.1003 59.6449 55.1132 59.4725 59.5477C59.4148 61.0343 62.0984 61.0212 62.2051 59.5477C62.5893 54.3012 61.9155 48.9173 58.7541 44.5703Z'
        fill='currentColor'
      />
    </svg>
  )
}
