import { PortableTextTypeComponent } from '@portabletext/react'

import { CarbonCalculatorWidget } from 'src/components/molecules/CarbonCalculatorWidget'

import { BodyElement, ElementOf } from '../types'

export const CO2WidgetBlock: PortableTextTypeComponent<BodyElement> = ({
  value
}) => {
  const { showExamples } = value as ElementOf<'co2Widget'>

  return <CarbonCalculatorWidget showExamples={showExamples} />
}
