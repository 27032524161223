import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextMarkComponentProps,
  PortableTextProps,
  PortableTextReactComponents
} from '@portabletext/react'

import { ExternalLink } from 'src/components/atoms/Link'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph
} from 'src/components/atoms/Typography'
import { CallOut } from 'src/components/atoms/Typography/Typography'

import { Body } from 'src/config/generated/sanitySchema'

import { Blockquote } from './blocks/Blockquote'
import { CO2WidgetBlock } from './blocks/CO2WidgetBlock'
import { CtaBlock } from './blocks/CtaBlock'
import { CtaBlockOld } from './blocks/CtaBlock/CtaBlock'
import { Image } from './blocks/Image'
import { InternalRefBlock } from './blocks/InternalRefBlock'
import { Link } from './blocks/Link'
import { Number, Bullet } from './blocks/List'

export type TextProps = PortableTextProps
export type TextValue = TextProps['value']

const defaultComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: Link,
    internalRefBlock: InternalRefBlock
  },
  block: {
    // @ts-ignore ... PortableText ts code is a bit whacky
    normal: Paragraph as PortableTextBlockComponent
  }
}

export const Text = ({ components, ...rest }: TextProps) => {
  return (
    <PortableText
      components={{ ...defaultComponents, ...components }}
      {...rest}
    />
  )
}

const contentComponents = {
  types: {
    image: Image,
    co2Widget: CO2WidgetBlock,
    ctaBlock: CtaBlock
  },
  marks: {
    link: ({
      children,
      value
    }: PortableTextMarkComponentProps<{ _type: 'link'; href: string }>) => {
      return <ExternalLink href={value?.href || ''}>{children}</ExternalLink>
    },
    ctaBlock: CtaBlockOld as any // temp fix - should remove this in the future
  },
  block: {
    // @ts-ignore ... PortableText ts code is a bit whacky
    normal: Paragraph as PortableTextBlockComponent,
    blockquote: Blockquote,
    // @ts-ignore
    h1: Heading1 as PortableTextBlockComponent,
    // @ts-ignore
    h2: Heading2 as PortableTextBlockComponent,
    // @ts-ignore
    h3: Heading3 as PortableTextBlockComponent,
    // @ts-ignore
    h4: Heading4 as PortableTextBlockComponent,
    // @ts-ignore
    callout: CallOut as PortableTextBlockComponent
  },
  list: {
    number: Number,
    bullet: Bullet
  }
}

export const Content = ({ body }: { body: Body }) => {
  return <Text components={contentComponents} value={body} />
}
