import { Option } from 'src/types/form'
import { ListRegionForFormType } from 'src/types/region'

export const buildRegionOptions = (
  regions?: ListRegionForFormType[],
  nullOption: Option = {
    label: 'Where trees are needed most',
    value: 'choose_for_me'
  }
): Option[] => {
  const options =
    regions?.reduce((arr: Option[], region: ListRegionForFormType) => {
      let label = region.ProperSpelling || region.Name
      if (region.AltName) label += ` - ${region.AltName}`

      return [
        ...arr,
        {
          label,
          value: region.Id
        }
      ]
    }, []) || []

  const sortedOptions = options.sort((a: Option, b: Option) =>
    (a.label as string).localeCompare(b.label as string)
  )

  sortedOptions.unshift(nullOption)

  return sortedOptions
}
