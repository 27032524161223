import Dropdown from 'rc-dropdown'
import 'rc-dropdown/assets/index.css'
import { useEffect } from 'react'
import { FaUser } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useMenuState } from 'src/providers/MenuStateProvider'

import { Button } from 'src/components/atoms/Button'

import useMediaQuery from 'src/hooks/useMediaQuery'

import { AccountMenu } from './AccountMenu'

export const AccountDropdown = () => {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const { isOpen, setIsOpen } = useMenuState()

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false)
    }
  }, [isMobile, setIsOpen])

  return (
    <Dropdown
      onVisibleChange={v => {
        if (!isMobile) setIsOpen(v)
      }}
      placement='bottomRight'
      visible={isOpen}
      trigger='click'
      overlay={<AccountMenu />}
    >
      <div>
        <Button
          size='large'
          variant='outlined'
          className='font-semibold focus:shadow-none h-11'
        >
          <div className='flex flex-row items-center gap-[5px]'>
            <FaUser width={15} height={17} />
            <span>Kia ora</span>
            {isOpen ? (
              <MdKeyboardArrowUp size='20px' />
            ) : (
              <MdKeyboardArrowDown size='20px' />
            )}
          </div>
        </Button>
      </div>
    </Dropdown>
  )
}
