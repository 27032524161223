import { InferType } from 'yup'

import { validationSchema } from './validationSchema'

export type CarbonCalculatorValues = InferType<typeof validationSchema>

export enum CalculatorTab {
  Carbon = 'carbon',
  Stems = 'stems'
}
