import { ListDistrictType } from 'src/types/district'
import { Option } from 'src/types/form'
import { DEFAULT_SELECT_OPTION } from 'src/utils/form'

export const buildDistrictsOptions = (
  districts?: ListDistrictType[],
  selectedRegionId?: string
): Option[] => {
  const defaultOptions = [
    { label: 'Please select a region first...', value: '', disabled: true }
  ]
  if (!selectedRegionId) return defaultOptions

  const options =
    districts?.reduce((arr: Option[], district: ListDistrictType) => {
      const label = district.ProperSpelling || district.Name

      if (
        district.RegionDistricts.some(rd => rd.RegionId === selectedRegionId)
      ) {
        return [
          ...arr,
          {
            label,
            value: district.Id
          }
        ]
      }

      return [...arr]
    }, []) || defaultOptions

  const sortedOptions = options.sort((a: Option, b: Option) =>
    (a.label as string).localeCompare(b.label as string)
  )

  sortedOptions.unshift(DEFAULT_SELECT_OPTION)

  return sortedOptions
}
