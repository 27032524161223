import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { useGetProjectNoteByIdQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewProjectNoteDetailBreadcrumb = () => {
  const { projectNoteId } = useRouter().query as { projectNoteId: string }

  const { data } = useGetProjectNoteByIdQuery({
    fetchPolicy: 'network-only',
    variables: { id: projectNoteId }
  })
  const reference = useMemo(
    () => data?.ProjectNote_by_pk?.Reference.toString(),
    [data]
  )

  const breadcrumbText = reference ? truncateBreadcrumbText(reference) : ''

  return <span>{breadcrumbText}</span>
}
